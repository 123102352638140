import { useQuery } from '@apollo/client';
import { GET_AVAILABLE_BRANDS_FOR_LEFT_NAVIGATION_QUERY } from '../queries/GetAvailableBrandsForLeftNavigation.query';
import { GetAvailableBrandsForLeftNavigation } from '../queries/__graphqlTypes/GetAvailableBrandsForLeftNavigation';

export function useUserBrandAccessCount(): {
    loading: boolean,
    userBrandCount: number | null,
} {
    const { data: availableBrandsData, loading } = useQuery<
        GetAvailableBrandsForLeftNavigation
    >(GET_AVAILABLE_BRANDS_FOR_LEFT_NAVIGATION_QUERY, {
        fetchPolicy: 'cache-and-network',
    });

    const userBrandCount = availableBrandsData?.me.agency.brands.edges.length ?? null;

    return { loading, userBrandCount };
}
