import * as React from 'react';
import { doesUrlMatchNavigationParts, NavigationItemUrlParts } from '@deltasierra/features/left-navigation';
import { LeftNavigationView } from '../enums';

type UseNavigationSwitchViewProps = {
    currentUrlPath: string;
}

type UseNavigationSwitchViewReturn = {
    currentView: LeftNavigationView;
    onToggleView: (view: LeftNavigationView) => void;
}

// eslint-disable-next-line max-lines-per-function
export function useNavigationSwitchView({
    currentUrlPath,
}: UseNavigationSwitchViewProps): UseNavigationSwitchViewReturn {

    const [currentView, setCurrentView] = React.useState<LeftNavigationView>(LeftNavigationView.location);

    const onToggleView = React.useCallback((view: LeftNavigationView) => {
        setCurrentView(view);
    }, [])

    // When navigating to specific routes, switch to the appropriate view.
    // eslint-disable-next-line max-lines-per-function
    React.useEffect(() => {
        const brandViewRoutes: NavigationItemUrlParts[] = [
            { startsWith: '/brand/agency/brands' },
            {
                endsWith: '/engagement/overview',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/region-group-activity',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/location-activity',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/template-use',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/send',
                startsWith: '/brand/communicate',
            },
            {
                endsWith: '/send-report',
                startsWith: '/brand/communicate',
            },
            {
                startsWith: '/report/templates',
            },
            {
                endsWith: '/manage/locations/locations',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/regions-groups/regions-groups',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/users/users',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/connections/platforms',
                startsWith: '/brand/client/',
            },
            {
                startsWith: '/agency/clients',
            },
            {
                endsWith: '/manage/learn-menu',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/activities',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/template-categories',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/hashtags',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/fonts',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/colors',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/style-guide',
                startsWith: '/brand/client/',
            },
        ];
        const locationViewRoutes: NavigationItemUrlParts[] = [
            { exactMatch: '/' },
            { startsWith: '/trythis' },
            { startsWith: '/planner' },
            { startsWith: '/scheduled' },
            { startsWith: '/builderTemplateGallery' },
            { startsWith: '/assets/view' },
            { endsWith: '/overview', startsWith: '/reports/location/' },
            { startsWith: '/locationDetails' },
        ];

        const matchesLocationViewRoute = locationViewRoutes.some(
            locationViewRoute => doesUrlMatchNavigationParts(currentUrlPath, locationViewRoute),
        );
        const matchesBrandViewRoute = brandViewRoutes.some(
            brandViewRoute => doesUrlMatchNavigationParts(currentUrlPath, brandViewRoute),
        )

        if (matchesLocationViewRoute) {
            setCurrentView(LeftNavigationView.location)
        } else if (matchesBrandViewRoute) {
            setCurrentView(LeftNavigationView.brand);
        }
    }, [currentUrlPath]);

    return { currentView, onToggleView }
}
