import { gql } from '@apollo/client';

export const GET_AVAILABLE_BRANDS_FOR_LEFT_NAVIGATION_QUERY = gql`
    query GetAvailableBrandsForLeftNavigation {
        me {
            id
            agency {
                id
                brands {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
        }
    }
`;
