import * as React from 'react';
import { useLeftNavigation } from './hooks';
import { LeftNavigationTopBar } from './LeftNavigation/LeftNavigationTopBar';
import { LeftNavigationLeftDrawer } from './LeftNavigation/LeftNavigationLeftDrawer';

export function LeftNavigationContainer(): JSX.Element {
    const {
        currentView,
        isDrawerOpen,
        isLoadingDrawerPreference,
        navigation,
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
        onDrawerToggle,
        onToggleView,
        profileNavigation,
        userInfo,
    } = useLeftNavigation();

    return (
        <>
            <LeftNavigationTopBar
                helpUrl="/trythis"
                isOpenDrawer={isDrawerOpen}
                logoImageSrc="../../../img/DS_Horizontal Logo_RGB.svg"
                notifications={notifications}
                profileNavigation={profileNavigation}
                userInfo={userInfo}
                onClickDismissAllNotifications={onClickDismissAllNotifications}
                onClickDismissNotification={onClickDismissNotification}
                onClickHamburger={onDrawerToggle}
            />
            {!isLoadingDrawerPreference && (
                <LeftNavigationLeftDrawer
                    currentView={currentView}
                    isOpenDrawer={isDrawerOpen}
                    navigation={navigation}
                    onClickMore={onDrawerToggle}
                    onToggleView={onToggleView}
                />
            )}
        </>
    );
}

LeftNavigationContainer.displayName = 'LeftNavigationContainer';
